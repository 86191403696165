import { useDeviceStore } from '@/store/DeviceStore';
import { CameraListener } from 'mind-sdk-web';

export enum CameraEvent {
  RELEASED = 'camera:released',
}

export class CameraEventListener extends EventTarget implements CameraListener {
  onCameraReleasedForcibly() {
    console.log('Camera released forcibly');
    useDeviceStore.getState().setCamera(null);
    this.dispatchEvent(new Event(CameraEvent.RELEASED));
  }
}
