import { useConferenceStore } from '@/store/ConferenceStore';
import { BaseLogger } from './base-logger';
import { getBrowserInfo, LoggerConfig, NetworkRequestData } from './types';

const SENSITIVE_HEADERS = [
  'authorization',
  'cookie',
  'set-cookie',
  'x-auth-token',
  'jwt',
  'bearer',
  'proxy-authorization',
];

class NetworkLogger extends BaseLogger<NetworkRequestData> {
  private originalFetch: typeof fetch;
  private cleanupXHR: () => void;

  constructor(config: LoggerConfig) {
    super(config);
    this.originalFetch = window.fetch;
    this.cleanupXHR = this.setupXHRInterceptor();
    this.setupFetchInterceptor();
  }

  private setupXHRInterceptor(): () => void {
    const originalXHROpen = XMLHttpRequest.prototype.open;
    const originalXHRSend = XMLHttpRequest.prototype.send;
    const debug = this.config.debug;
    const addToQueue = this.addToQueue.bind(this);
    const getDecodedUserId = this.getDecodedUserId.bind(this);

    XMLHttpRequest.prototype.open = function (
      method: string,
      url: string | URL,
      async: boolean = true,
      username?: string | null,
      password?: string | null
    ): void {
      this._networkLoggerData = {
        method: method.toUpperCase(),
        url: url.toString(),
        startTime: 0,
        requestHeaders: {},
      };
      originalXHROpen.apply(this, [method, url, async, username, password]);
    };

    XMLHttpRequest.prototype.send = function (body?: Document | XMLHttpRequestBodyInit | null): void {
      const networkLoggerData = this._networkLoggerData;
      if (!networkLoggerData) {
        originalXHRSend.apply(this, [body]);
        return;
      }

      networkLoggerData.startTime = performance.now();

      if (body && typeof body === 'string') {
        try {
          JSON.parse(body);
          networkLoggerData.requestBody = body;
        } catch {
          if (body.length < 10000) {
            networkLoggerData.requestBody = body;
          }
        }
      }

      const originalSetRequestHeader = this.setRequestHeader;
      this.setRequestHeader = function (header: string, value: string): void {
        if (!SENSITIVE_HEADERS.includes(header.toLowerCase())) {
          networkLoggerData.requestHeaders[header] = value;
        }
        originalSetRequestHeader.apply(this, [header, value]);
      };

      this.addEventListener('load', function () {
        const endTime = performance.now();
        const responseHeaders: Record<string, string> = {};
        const allHeaders = this.getAllResponseHeaders();

        allHeaders
          .trim()
          .split(/[\r\n]+/)
          .forEach((line) => {
            const parts = line.split(': ');
            const header = parts.shift();
            if (header && !SENSITIVE_HEADERS.includes(header.toLowerCase())) {
              responseHeaders[header] = parts.join(': ');
            }
          });

        let responseBody: string | undefined;
        const contentType = this.getResponseHeader('content-type');
        if (contentType && (contentType.includes('application/json') || contentType.includes('text/'))) {
          try {
            responseBody = this.responseText;
            if (responseBody.length > 10000) {
              responseBody = responseBody.substring(0, 10000) + '... (truncated)';
            }
          } catch (error) {
            if (debug) {
              console.warn('[NetworkLogger] Failed to read XHR response body:', error);
            }
          }
        }

        const networkData: NetworkRequestData = {
          url: networkLoggerData.url,
          duration: Math.round((endTime - networkLoggerData.startTime) * 100) / 100,
          initiatorType: 'xhr',
          transferSize: this.response?.length || 0,
          status: this.status,
          timestamp: Math.round(performance.timeOrigin + networkLoggerData.startTime),
          method: networkLoggerData.method,
          requestHeaders: networkLoggerData.requestHeaders,
          responseHeaders,
          requestBody: networkLoggerData.requestBody,
          responseBody,
          ...getBrowserInfo(),
          roomId: useConferenceStore.getState().conference?.roomId ?? '',
          conferenceId: useConferenceStore.getState().conference?.id ?? '',
          participantId:
            useConferenceStore
              .getState()
              .participants.find((p) => p.isMe())
              ?.getId() ?? '',
          userId: getDecodedUserId() ?? '',
        };

        addToQueue(networkData);
      });

      originalXHRSend.apply(this, [body]);
    };

    return () => {
      XMLHttpRequest.prototype.open = originalXHROpen;
      XMLHttpRequest.prototype.send = originalXHRSend;
    };
  }

  private setupFetchInterceptor(): void {
    const originalFetch = window.fetch;
    window.fetch = async (input: RequestInfo | URL, init?: RequestInit) => {
      const startTime = performance.now();
      const url = input instanceof Request ? input.url : input.toString();
      const method = (init?.method || 'GET').toUpperCase();

      let requestHeaders: Record<string, string> = {};
      let requestBody: string | undefined;

      if (init?.headers) {
        const headers = new Headers(init.headers);
        requestHeaders = Object.fromEntries(
          Array.from(headers.entries()).filter(([key]) => !SENSITIVE_HEADERS.includes(key.toLowerCase()))
        );
      }

      if (init?.body && typeof init.body === 'string') {
        try {
          JSON.parse(init.body);
          requestBody = init.body;
        } catch {
          if (init.body.length < 10000) {
            requestBody = init.body;
          }
        }
      }

      const response = await originalFetch(input, init);
      const endTime = performance.now();

      const responseClone = response.clone();

      let responseBody: string | undefined;
      const responseHeaders: Record<string, string> = {};

      response.headers.forEach((value, key) => {
        if (!SENSITIVE_HEADERS.includes(key.toLowerCase())) {
          responseHeaders[key] = value;
        }
      });

      const contentType = response.headers.get('content-type');
      if (contentType && (contentType.includes('application/json') || contentType.includes('text/'))) {
        try {
          responseBody = await responseClone.text();
          if (responseBody.length > 10000) {
            responseBody = responseBody.substring(0, 10000) + '... (truncated)';
          }
        } catch (error) {
          if (this.config.debug) {
            console.warn('[NetworkLogger] Failed to read response body:', error);
          }
        }
      }

      const networkData: NetworkRequestData = {
        url,
        duration: Math.round((endTime - startTime) * 100) / 100,
        initiatorType: 'fetch',
        transferSize: 0,
        status: response.status,
        timestamp: Math.round(performance.timeOrigin + startTime),
        method,
        requestHeaders,
        responseHeaders,
        requestBody,
        responseBody,
        ...getBrowserInfo(),
        roomId: useConferenceStore.getState().conference?.roomId ?? '',
        conferenceId: useConferenceStore.getState().conference?.id ?? '',
        participantId:
          useConferenceStore
            .getState()
            .participants.find((p) => p.isMe())
            ?.getId() ?? '',
        userId: this.getDecodedUserId() ?? '',
      };

      this.addToQueue(networkData);

      return response;
    };
  }

  public override cleanup(): void {
    super.cleanup();
    window.fetch = this.originalFetch;
    this.cleanupXHR();
  }
}

const setupNetworkLogger = (config: LoggerConfig): (() => void) => {
  const logger = new NetworkLogger(config);
  return () => logger.cleanup();
};

export default setupNetworkLogger;
