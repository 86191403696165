import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useRef } from 'react';

export function useTransientNavigate<T>(paramKey?: string) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isSearchParam = Boolean(paramKey);
  const stateRef = useRef<T | null>(
    isSearchParam ? (searchParams.get(paramKey!) as unknown as T) : (location.state as T)
  );

  const getTransientState = useCallback((): T | null => {
    const state = stateRef.current;
    if (!state) return null;

    stateRef.current = null;

    setTimeout(() => {
      if (isSearchParam) {
        setSearchParams(
          (params) => {
            params.delete(paramKey!);
            return params;
          },
          { replace: true }
        );
      } else {
        navigate(location.pathname, { replace: true, state: null });
      }
    }, 0);

    return state;
  }, [isSearchParam, paramKey, setSearchParams, navigate, location.pathname]);

  const transientNavigate = useCallback(
    (to: string, state: T) => {
      if (isSearchParam) {
        setSearchParams(
          (params) => {
            params.set(paramKey!, String(state));
            return params;
          },
          { replace: true }
        );
      } else {
        navigate(to, { state });
      }
    },
    [isSearchParam, paramKey, setSearchParams, navigate]
  );

  return { transientNavigate, getTransientState };
}
