import { ACCESS_TOKEN_KEY, ANONYMOUS_TOKEN_KEY } from '@/service/AuthService';
import { useConferenceStore } from '@/store/ConferenceStore';
import * as jose from 'jose';
import { BaseLogData, getBrowserInfo, LoggerConfig } from './types';

export abstract class BaseLogger<T extends BaseLogData> {
  protected static readonly SENSITIVE_PATTERNS = [
    /password/i,
    /passwd/i,
    /secret/i,
    /authorization/i,
    /api[-._]?key/i,
    /access[-._]?token/i,
    /refresh[-._]?token/i,
    /bearer/i,
    /session[-._]?id/i,
    /ssn/i,
    /credit[-._]?card/i,
    /card[-._]?number/i,
    /cvv/i,
  ];

  protected config: Required<LoggerConfig>;
  protected requestQueue: T[] = [];
  protected failedAttempts = 0;
  protected isDisabled = false;
  protected flushIntervalId: number;

  constructor(config: LoggerConfig) {
    this.config = {
      endpoint: config.endpoint,
      batchSize: config.batchSize ?? 10,
      flushInterval: config.flushInterval ?? 5000,
      debug: config.debug ?? false,
      maxRetries: config.maxRetries ?? 3,
    };

    this.flushIntervalId = window.setInterval(() => this.flushQueue(), this.config.flushInterval);
  }

  protected sanitizeValue(value: unknown): unknown {
    if (typeof value === 'string') {
      // Check if the string matches any sensitive patterns
      if (BaseLogger.SENSITIVE_PATTERNS.some((pattern) => pattern.test(value))) {
        return '[REDACTED]';
      }
      // Check if it's a JSON string
      try {
        const parsed = JSON.parse(value);
        return this.sanitizeObject(parsed);
      } catch {
        return value;
      }
    } else if (Array.isArray(value)) {
      return value.map((item) => this.sanitizeValue(item));
    } else if (value && typeof value === 'object') {
      return this.sanitizeObject(value as Record<string, unknown>);
    }
    return value;
  }

  protected sanitizeObject(obj: Record<string, unknown>): Record<string, unknown> {
    const sanitized: Record<string, unknown> = Object.create(null);

    for (const [key, value] of Object.entries(obj)) {
      // Check if the key matches any sensitive patterns
      if (BaseLogger.SENSITIVE_PATTERNS.some((pattern) => pattern.test(key))) {
        sanitized[key] = '[REDACTED]';
      } else {
        sanitized[key] = this.sanitizeValue(value);
      }
    }

    return sanitized;
  }

  protected handleFailedAttempt(error?: string): void {
    this.failedAttempts++;
    if (this.config.debug) {
      console.error('[Logger] Error sending data:', error);
      console.warn(`[Logger] Failed attempt ${this.failedAttempts}/${this.config.maxRetries}`);
    }

    if (this.failedAttempts >= this.config.maxRetries) {
      this.isDisabled = true;
      if (this.config.debug) {
        console.error('[Logger] Max retry attempts reached. Logging will be disabled.');
      }
    }
  }

  protected sendData(data: T[]): void {
    if (this.isDisabled) return;

    try {
      // Sanitize the data before sending
      const sanitizedData = data.map((item) => this.sanitizeObject(item as Record<string, unknown>)) as T[];

      const blob = new Blob([JSON.stringify(sanitizedData)], { type: 'application/json' });
      const success = navigator.sendBeacon(this.config.endpoint, blob);

      if (success) {
        this.failedAttempts = 0;
        if (this.config.debug) {
          console.log(`[Logger] Successfully sent ${data.length} items`);
        }
      } else {
        this.handleFailedAttempt('SendBeacon failed');
      }
    } catch (error) {
      this.handleFailedAttempt(error instanceof Error ? error.message : 'Unknown error');
    }
  }

  protected flushQueue(): void {
    if (this.requestQueue.length > 0) {
      this.sendData([...this.requestQueue]);
      this.requestQueue = [];
    }
  }

  protected getCommonData(): Omit<BaseLogData, 'timestamp'> {
    return {
      ...getBrowserInfo(),
      roomId: useConferenceStore.getState().conference?.roomId ?? '',
      conferenceId: useConferenceStore.getState().conference?.id ?? '',
      participantId:
        useConferenceStore
          .getState()
          .participants.find((p) => p.isMe())
          ?.getId() ?? '',
      userId: this.getDecodedUserId() ?? '',
    };
  }

  protected getDecodedUserId(): string | undefined {
    let token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token) {
      token = localStorage.getItem(ANONYMOUS_TOKEN_KEY);
    }
    const decodedToken = token ? jose.decodeJwt(token) : null;
    return decodedToken?.sub;
  }

  protected addToQueue(item: T): void {
    this.requestQueue.push(item);
    if (this.requestQueue.length >= this.config.batchSize) {
      this.flushQueue();
    }
  }

  public cleanup(): void {
    clearInterval(this.flushIntervalId);
    if (this.requestQueue.length > 0) {
      this.sendData([...this.requestQueue]);
    }
  }
}
