import { AudioStreamListener } from '@/event/AudioStreamListener.ts';
import { MindParticipantWrapper, PresentingParticipant } from '@/model/Participant.ts';
import { ConferenceService, getConferenceService } from '@/service/ConferenceService';
import { useAudioAnalizerStore } from '@/store/AudioAnalizerStore.ts';
import { useConferenceStore } from '@/store/ConferenceStore';
import { ConferencePageState, useUIStore } from '@/store/UIStore.ts';
import { Me, Participant, SessionListener } from 'mind-sdk-web';

export class SessionEventListener extends SessionListener {
  constructor(private readonly conferenceService: ConferenceService) {
    super();
  }

  onParticipantJoined(participant: Participant) {
    participant.getMediaStream().addAudioConsumer(new AudioStreamListener(participant.getId()));
    const participantWrapper = new MindParticipantWrapper(participant);
    useConferenceStore.getState().addParticipant(participantWrapper);

    this.conferenceService
      .getUserByParticipantId(participant.getId())
      .then((user) => {
        participantWrapper.setAnonymous(!user);
        useConferenceStore.getState().updateParticipant(participantWrapper);
      })
      .catch(console.error);
  }

  onParticipantExited(participant: Participant) {
    useAudioAnalizerStore.getState().removeAnalyzer(participant.getId());
    useConferenceStore.getState().removeParticipant(participant.getId());

    this.removePresentingParticipant(participant);
  }

  onMeExpelled() {
    // TODO: Perhaps to show a message to the user instead of redirecting, when the one is kicked out of the conference
    useUIStore.getState().setConferencePageState(ConferencePageState.FINISHED);
  }

  onConferenceRecordingStarted(): void {
    useUIStore.getState().setRecordingEnabled(true);
  }

  onConferenceRecordingStopped(): void {
    useUIStore.getState().setRecordingEnabled(false);
  }

  onConferenceEnded() {
    useAudioAnalizerStore.getState().removeAllAnalyzer();
    getConferenceService()?.closeSession();
    useUIStore.getState().setConferencePageState(ConferencePageState.FINISHED);
  }

  onParticipantMediaChanged(participant: Participant): void {
    this.onMindParticipantUpdatedImpl(participant);
  }

  onParticipantSecondaryMediaChanged(participant: Participant): void {
    this.onMindParticipantUpdatedImpl(participant);

    if (participant.isStreamingSecondaryVideo()) {
      const stream = participant.getSecondaryMediaStream();
      stream.setMaxVideoFrameArea(1920 * 1080);
      stream.setMaxVideoFrameRate(30);
      stream.setMaxVideoBitrate(12_000_000); // 12 Mbps

      useConferenceStore.getState().addParticipant(new PresentingParticipant(participant));
    } else {
      this.removePresentingParticipant(participant);
    }
  }

  onParticipantRoleChanged(participant: Participant) {
    this.onMindParticipantUpdatedImpl(participant);
  }

  onMeRoleChanged(me: Me): void {
    this.onMindParticipantUpdatedImpl(me);
  }

  private onMindParticipantUpdatedImpl(mindParticipant: Participant) {
    const participants = useConferenceStore.getState().participants;
    const participant = participants.find((p) => p.getId() === mindParticipant.getId());
    if (participant) {
      (participant as MindParticipantWrapper).setMindParticipant(mindParticipant);
      useConferenceStore.getState().updateParticipant(participant);
    }
  }

  private removePresentingParticipant(participant: Participant) {
    const conferenceStore = useConferenceStore.getState();
    const id = conferenceStore.participants
      .find(
        (p) =>
          p.isPresentingParticipant() &&
          (p as PresentingParticipant).getMindParticipant().getId() === participant.getId()
      )
      ?.getId();
    id && conferenceStore.removeParticipant(id);
  }
}
