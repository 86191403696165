import { DeviceRegistryEventListener } from '@/event/DeviceRegistryEventListener.ts';
import { ScreenEventListener } from '@/event/ScreenEventListener.ts';
import { DefaultDeviceRecogniser } from '@/lib/default-device-recogniser.ts';
import { decorateDevicesDisplayName, filterMediaDevices } from '@/lib/filter-media-devices.ts';
import { Speaker } from '@/lib/speaker-registry.ts';
import { IConferenceSDK } from '@/sdk/ConferenceSDK.ts';
import { useDeviceStore } from '@/store/DeviceStore.ts';
import { Camera, Microphone } from 'mind-sdk-web';
import { create } from 'zustand';
import { CameraManager, MicrophoneManager, SpeakerManager } from './DeviceManager';

export const getDeviceService = (): DeviceService | undefined => useDeviceServiceStore.getState().deviceService;
export const useDeviceService = (): DeviceService | undefined => useDeviceServiceStore((state) => state.deviceService);

export const initializeDeviceService = (sdk: IConferenceSDK): void => {
  if (!useDeviceServiceStore.getState().deviceService) {
    useDeviceServiceStore.getState().setDeviceService(new DeviceServiceImpl(sdk));
  }
};

export interface DeviceService {
  setupMediaDevices(): Promise<void>;
  useMicrophone(microphone: Microphone): Promise<void>;
  toggleMicrophone: () => Promise<void>;
  muteMicrophone: () => Promise<void>;
  useCamera(camera: Camera): Promise<void>;
  toggleCamera: () => Promise<void>;
  useSpeaker(speaker: Speaker): Promise<void>;
  setupPrimaryMediaStream(): Promise<void>;
  setupSecondaryMediaStream(): Promise<void>;
}

export class DeviceServiceImpl implements DeviceService {
  private readonly sdk: IConferenceSDK;
  private readonly defaultDeviceRecogniser = new DefaultDeviceRecogniser();
  private readonly microphoneManager: MicrophoneManager;
  private readonly cameraManager: CameraManager;
  private readonly speakerManager: SpeakerManager;

  private deviceRegistryListener: DeviceRegistryEventListener | null = null;

  constructor(sdk: IConferenceSDK) {
    this.sdk = sdk;
    this.microphoneManager = new MicrophoneManager(this, sdk);
    this.cameraManager = new CameraManager(this);
    this.speakerManager = new SpeakerManager();
  }

  /**
   * Initializes all media devices and sets up device registry listeners
   */
  async setupMediaDevices(): Promise<void> {
    try {
      await this.defaultDeviceRecogniser.updateFromNavigator();

      const deviceStore = useDeviceStore.getState();
      deviceStore.setAvailableMicrophones(
        this.defaultDeviceRecogniser.markSystemDefaultDevices(
          decorateDevicesDisplayName(filterMediaDevices(this.sdk.getDeviceRegistry().getMicrophones()))
        )
      );
      deviceStore.setAvailableCameras(
        this.defaultDeviceRecogniser.markSystemDefaultDevices(
          decorateDevicesDisplayName(filterMediaDevices(this.sdk.getDeviceRegistry().getCameras()))
        )
      );
      deviceStore.setAvailableSpeakers(
        this.defaultDeviceRecogniser.markSystemDefaultDevices(
          decorateDevicesDisplayName(filterMediaDevices(this.sdk.getSpeakerRegistry().getSpeakers()))
        )
      );

      this.deviceRegistryListener = new DeviceRegistryEventListener(this);
      this.sdk.getDeviceRegistry().setListener(this.deviceRegistryListener);
      this.sdk.getSpeakerRegistry().setListener(this.deviceRegistryListener);

      await this.microphoneManager.useDefaultDevice();
      await this.cameraManager.useDefaultDevice();
      await this.speakerManager.useDefaultDevice();
    } catch (error) {
      console.error('Error setting up media devices:', error);
    }
  }

  async useMicrophone(microphone: Microphone): Promise<void> {
    await this.microphoneManager.useDevice(microphone);
  }

  async toggleMicrophone(): Promise<void> {
    await this.microphoneManager.toggleDevice();
  }

  async muteMicrophone(): Promise<void> {
    if (this.microphoneManager.isEnabled()) {
      await this.toggleMicrophone();
    }
  }

  async useCamera(camera: Camera): Promise<void> {
    await this.cameraManager.useDevice(camera);
  }

  async toggleCamera(): Promise<void> {
    await this.cameraManager.toggleDevice();
  }

  async useSpeaker(speaker: Speaker): Promise<void> {
    await this.speakerManager.useDevice(speaker);
  }

  /**
   * Sets up the primary media stream with current microphone and camera
   */
  async setupPrimaryMediaStream(): Promise<void> {
    const microphone = this.microphoneManager.getCurrentDevice();
    const camera = this.cameraManager.getCurrentDevice();

    if (!microphone && !camera) {
      console.warn('No microphone or camera found');
      return;
    }

    const primaryMediaStream = this.sdk.createMediaStream(microphone, camera);
    useDeviceStore.getState().setPrimaryMediaStream(primaryMediaStream);
  }

  /**
   * Sets up the secondary media stream for screen sharing
   */
  async setupSecondaryMediaStream(): Promise<void> {
    const deviceRegistry = this.sdk.getDeviceRegistry();
    const screen = deviceRegistry.getScreen();
    if (!screen) {
      console.warn('No screen found for secondary media stream');
      return;
    }

    screen.setListener(new ScreenEventListener());
    useDeviceStore.getState().setScreen(screen);

    const secondaryMediaStream = this.sdk.createMediaStream(screen, screen);
    useDeviceStore.getState().setSecondaryMediaStream(secondaryMediaStream);
  }

  /**
   * Cleans up device registry listeners
   */
  cleanup(): void {
    if (this.deviceRegistryListener) {
      this.sdk.getDeviceRegistry().setListener(null);
      this.sdk.getSpeakerRegistry().setListener(null);
      this.deviceRegistryListener = null;
    }
  }
}

interface DeviceServiceStore {
  deviceService: DeviceService | undefined;
  setDeviceService: (service: DeviceService) => void;
}

const useDeviceServiceStore = create<DeviceServiceStore>((set) => ({
  deviceService: undefined,
  setDeviceService: (service: DeviceService) => set({ deviceService: service }),
}));
