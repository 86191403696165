import CopyTextDialog from '@/components/dialog/CopyTextDialog.tsx';
import { DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu.tsx';
import { useTransientNavigate } from '@/hook/useTransientNavigate.ts';
import { createEventInGoogleCalendar } from '@/lib/calendar.ts';
import { ConferencePageLocationState } from '@/pages/ConferencePage.tsx';
import { useAuthService } from '@/service/AuthService.ts';
import { useConferenceService } from '@/service/ConferenceService.ts';
import { FC, useCallback, useState } from 'react';
import { FaCalendarAlt, FaClock, FaPlay } from 'react-icons/fa';
import { toast } from 'sonner';

type CreateConferenceMenuProps = {
  onProcessing?: (processing: boolean) => void;
};

const CreateConferenceMenu: FC<CreateConferenceMenuProps> = ({ onProcessing }) => {
  const { transientNavigate } = useTransientNavigate<ConferencePageLocationState>();
  const conferenceService = useConferenceService();
  const authService = useAuthService();

  const [isConferenceLinkDialogOpen, setIsConferenceLinkDialogOpen] = useState(false);
  const [conferenceLink, setConferenceRoomUrl] = useState<string | null>(null);

  const createConferenceRoom = useCallback(async (): Promise<string | null> => {
    if (!authService) return null;

    onProcessing && onProcessing(true);
    try {
      if (!(await authService.isAuthorized())) {
        authService.login();
        return null;
      }

      if (!conferenceService) {
        throw new Error('Conference service is not available');
      }

      return await conferenceService.createConferenceRoom();
    } catch (e) {
      console.error(e);
      toast.error(`An error occurred while creating meeting room`);
    } finally {
      onProcessing && onProcessing(false);
    }

    return null;
  }, [onProcessing, authService, conferenceService]);

  const handleCreateConferenceForLater = useCallback(async () => {
    const roomId = await createConferenceRoom();
    if (roomId) {
      setConferenceRoomUrl(`${import.meta.env.VITE_APP_BASE_URL}/${roomId}`);
      setIsConferenceLinkDialogOpen(true);
    }
  }, [createConferenceRoom]);

  const handleCreateInstantConference = useCallback(async () => {
    onProcessing && onProcessing(true);
    try {
      const roomId = await createConferenceRoom();
      if (roomId) {
        transientNavigate(`/${roomId}`, { skipLobby: true });
      }
    } finally {
      onProcessing && onProcessing(false);
    }
  }, [createConferenceRoom, onProcessing, transientNavigate]);

  const handleCreateConferenceInGoogleCalendar = useCallback(async () => {
    onProcessing && onProcessing(true);
    try {
      const newTab = window.open(); // Open window immediately to avoid popup blocking
      const roomId = await createConferenceRoom();
      if (roomId && newTab) {
        createEventInGoogleCalendar(`${import.meta.env.VITE_APP_BASE_URL}/${roomId}`, newTab);
      }
    } finally {
      onProcessing && onProcessing(false);
    }
  }, [createConferenceRoom, onProcessing]);

  return (
    <>
      <DropdownMenuContent align="start">
        <DropdownMenuItem
          className="text-base p-2 text-muted-foreground"
          onClick={handleCreateConferenceForLater}
          disabled={!conferenceService}
        >
          <FaClock className="inline w-3 h-3 mr-3" />
          Create a meeting for later
        </DropdownMenuItem>
        <DropdownMenuItem
          className="text-base p-2 text-muted-foreground"
          onClick={handleCreateInstantConference}
          disabled={!conferenceService}
        >
          <FaPlay className="inline w-3 h-3 mr-3" />
          Start an instant meeting
        </DropdownMenuItem>
        <DropdownMenuItem
          className="text-base p-2 text-muted-foreground"
          onClick={handleCreateConferenceInGoogleCalendar}
          disabled={!conferenceService}
        >
          <FaCalendarAlt className="inline w-3 h-3  mr-3" />
          Schedule in Google Calendar
        </DropdownMenuItem>
      </DropdownMenuContent>

      <CopyTextDialog
        open={isConferenceLinkDialogOpen}
        onOpenChange={setIsConferenceLinkDialogOpen}
        title="Here's your joining info"
        description="Send this to people you want to meet with. Be sure to save it so you can use it later."
        value={conferenceLink}
      />
    </>
  );
};

export default CreateConferenceMenu;
