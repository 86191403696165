import { Me, MediaStream, Participant as MindParticipant, ParticipantRole } from 'mind-sdk-web';

export abstract class Participant {
  abstract getId(): string;
  abstract getName(): string;
  abstract getMediaStream(): MediaStream | null;
  abstract isStreamingVideo(): boolean;
  abstract isStreamingAudio(): boolean;
  abstract getRole(): string;

  abstract isHandRaised(): boolean;
  abstract handRaisedAt(): number | null;

  abstract isPinned(): boolean;
  abstract setPinned(pinned: boolean): void;

  abstract shouldIgnoreVideo(): boolean;
  abstract setIgnoreVideo(ignore: boolean): void;

  abstract isMe(): boolean;
  abstract isReal(): boolean;
  abstract isVoiceAssistant(): boolean;
  abstract isPresentingParticipant(): boolean;
  abstract isAnonymous(): boolean;
  isModerator(): boolean {
    return this.getRole() === ParticipantRole.MODERATOR;
  }

  abstract isVideoFloating(): boolean;
  abstract setVideoFloating(floating: boolean): void;
}

export class MindParticipantWrapper extends Participant {
  private _mindParticipant: MindParticipant;
  private _handRaisedAt: number | null = null;
  private readonly _isMe: boolean = false;
  private _pinned: boolean = false;
  private _ignoreVideo: boolean = false;
  private _videoFloating: boolean = true;
  private _anonymous: boolean = false;

  constructor(participant: MindParticipant, isMe: boolean = false) {
    super();
    this._mindParticipant = participant;
    this._isMe = isMe;
  }

  getId(): string {
    return this._mindParticipant.getId();
  }

  getName(): string {
    return this._mindParticipant.getName();
  }

  getMediaStream(): MediaStream | null {
    return this._mindParticipant.getMediaStream();
  }

  isStreamingVideo(): boolean {
    return this._mindParticipant.isStreamingVideo();
  }

  isStreamingAudio(): boolean {
    return this._mindParticipant.isStreamingAudio();
  }

  getRole(): string {
    return this._mindParticipant.getRole();
  }

  isHandRaised(): boolean {
    return this._handRaisedAt !== null;
  }

  handRaisedAt(): number | null {
    return this._handRaisedAt;
  }

  isPinned(): boolean {
    return this._pinned;
  }

  setPinned(pinned: boolean): void {
    this._pinned = pinned;
  }

  setHandRaisedAt(time: number | null): void {
    this._handRaisedAt = time;
  }

  isVideoFloating(): boolean {
    return this._videoFloating;
  }

  setVideoFloating(floating: boolean): void {
    this._videoFloating = floating;
  }

  shouldIgnoreVideo(): boolean {
    return this._ignoreVideo;
  }

  setIgnoreVideo(ignore: boolean): void {
    this._ignoreVideo = ignore;
  }

  isMe(): boolean {
    return this._isMe;
  }

  isReal(): boolean {
    return true;
  }

  isVoiceAssistant(): boolean {
    return this.getName().toLowerCase().includes('voice assistant');
  }

  isPresentingParticipant(): boolean {
    return false;
  }

  isAnonymous(): boolean {
    return this._anonymous;
  }

  setAnonymous(anonymous: boolean): void {
    this._anonymous = anonymous;
  }

  getMindParticipant(): MindParticipant {
    return this._mindParticipant;
  }

  setMindParticipant(participant: MindParticipant): void {
    this._mindParticipant = participant;
  }

  setMediaStream(mediaStream: MediaStream | null): void {
    if (!this._isMe) {
      console.warn('The participant is not a Me');
      return;
    }

    (this._mindParticipant as Me).setMediaStream(mediaStream);
  }

  setSecondaryMediaStream(mediaStream: MediaStream | null): void {
    if (!this._isMe) {
      console.warn('The participant is not a Me');
      return;
    }

    (this._mindParticipant as Me).setSecondaryMediaStream(mediaStream);
  }
}

export class PresentingParticipant extends Participant {
  private _mindParticipant: MindParticipant;
  private _pinned: boolean = true; // Pinned by default
  private _ignoreVideo: boolean = false;

  constructor(participant: MindParticipant) {
    super();
    this._mindParticipant = participant;
  }

  getId(): string {
    return this._mindParticipant.getId() + '-presenting';
  }

  getName(): string {
    return this._mindParticipant.getName();
  }

  getMediaStream(): MediaStream | null {
    return this._mindParticipant.getSecondaryMediaStream();
  }

  isStreamingVideo(): boolean {
    return this._mindParticipant.isStreamingSecondaryVideo();
  }

  isStreamingAudio(): boolean {
    return this._mindParticipant.isStreamingSecondaryAudio();
  }

  getRole(): string {
    return ParticipantRole.ATTENDEE;
  }

  isHandRaised(): boolean {
    return false;
  }

  handRaisedAt(): number | null {
    return null;
  }

  isPinned(): boolean {
    return this._pinned;
  }

  setPinned(pinned: boolean): void {
    this._pinned = pinned;
  }

  shouldIgnoreVideo(): boolean {
    return this._ignoreVideo;
  }

  setIgnoreVideo(ignore: boolean): void {
    this._ignoreVideo = ignore;
  }

  setHandRaisedAt(): void {}

  isVideoFloating(): boolean {
    return false;
  }

  setVideoFloating(): void {}

  isMe(): boolean {
    return false;
  }

  isReal(): boolean {
    return false;
  }

  isVoiceAssistant(): boolean {
    return false;
  }

  isPresentingParticipant(): boolean {
    return true;
  }

  isAnonymous(): boolean {
    return false;
  }

  getMindParticipant(): MindParticipant {
    return this._mindParticipant;
  }

  setMindParticipant(participant: MindParticipant): void {
    this._mindParticipant = participant;
  }
}

export class MockParticipant extends Participant {
  private readonly _id: string;
  private readonly _name: string;
  private pinned: boolean = false;

  constructor(id: string, name: string) {
    super();
    this._id = id;
    this._name = name;
  }

  getId(): string {
    return this._id;
  }

  getName(): string {
    return this._name;
  }

  getMediaStream(): MediaStream | null {
    return null;
  }

  isStreamingVideo(): boolean {
    return false;
  }

  isStreamingAudio(): boolean {
    return false;
  }

  getRole(): string {
    return ParticipantRole.ATTENDEE;
  }

  isHandRaised(): boolean {
    return false;
  }

  handRaisedAt(): number | null {
    return null;
  }

  isPinned(): boolean {
    return this.pinned;
  }

  setPinned(pinned: boolean): void {
    this.pinned = pinned;
  }

  shouldIgnoreVideo(): boolean {
    return false;
  }

  setIgnoreVideo(): void {}

  isVideoFloating(): boolean {
    return false;
  }

  setVideoFloating(): void {}

  isMe(): boolean {
    return false;
  }

  isReal(): boolean {
    return false;
  }

  isVoiceAssistant(): boolean {
    return false;
  }

  isPresentingParticipant(): boolean {
    return false;
  }

  isAnonymous(): boolean {
    return false;
  }
}
