import MessageSheet from '@/components/MessageSheet.tsx';
import { ConferenceNotFoundError } from '@/error/ConferenceNotFoundError.ts';
import { JoinRequestRejectedException } from '@/sdk/JoinRequestRejectedException.ts';
import { useConferenceService } from '@/service/ConferenceService.ts';
import { ConferencePageState, useUIStore } from '@/store/UIStore';
import { AxiosError } from 'axios';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

const ConferenceErrorBoundary = ({ children }: PropsWithChildren) => {
  const conferenceService = useConferenceService();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const setConferencePageState = useUIStore((state) => state.setConferencePageState);

  const errorHandler = useCallback(
    (e: Error | AxiosError) => {
      if (e instanceof ConferenceNotFoundError || (e instanceof AxiosError && e.response?.status === 404)) {
        setErrorMessages(['The meeting was not found.']);
      } else if (e instanceof AxiosError && e?.response?.data?.message === 'Conference capacity exceeded') {
        setErrorMessages(['The meeting capacity has been exceeded.']);
        conferenceService?.closeSession();
      } else if (e.message?.includes("Failed to execute 'setParameters' on 'RTCRtpSender'")) {
        console.warn('Unsupported scalability mode');
      } else if (
        e instanceof JoinRequestRejectedException ||
        (e instanceof AxiosError && e?.response?.data?.message === 'Join request was rejected')
      ) {
        setConferencePageState(ConferencePageState.FINISHED);
      } else {
        setErrorMessages([
          'An error occurred. Please try again or contact support.',
          e instanceof AxiosError ? e.response?.data?.message || e.message || '' : e.message || '',
          e.stack ? e.stack : '',
        ]);
        conferenceService?.closeSession();
      }
    },
    [conferenceService, setConferencePageState]
  );

  useEffect(() => {
    const errorListener = (event: ErrorEvent | PromiseRejectionEvent) => {
      if (event instanceof ErrorEvent) {
        errorHandler(event.error);
      } else if (event instanceof PromiseRejectionEvent) {
        errorHandler(event.reason);
      }
    };

    window.addEventListener('error', errorListener);
    window.addEventListener('unhandledrejection', errorListener);

    return () => {
      window.removeEventListener('error', errorListener);
      window.removeEventListener('unhandledrejection', errorListener);
    };
  }, [errorHandler]);

  if (errorMessages.length > 0) {
    return <MessageSheet message={errorMessages} />;
  }

  return <>{children}</>;
};

export default ConferenceErrorBoundary;
