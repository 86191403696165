import { ConferenceNotFoundError } from '@/error/ConferenceNotFoundError';
import { AudioStreamListener } from '@/event/AudioStreamListener.ts';
import { SessionEventListener } from '@/event/SessionEventListener';
import { MindParticipantWrapper, PresentingParticipant } from '@/model/Participant.ts';
import { User } from '@/model/User';
import { AIMessage } from '@/sdk/AIMessage';
import { ChatMessage } from '@/sdk/ChatMessage';
import { IConferenceSDK } from '@/sdk/ConferenceSDK.ts';
import { HistoryOptions, HistorySearchField, HistorySearchFilter } from '@/sdk/HistoryFilter';
import { HistoryRecord } from '@/sdk/HistoryRecord';
import { Participant, PARTICIPANT_ROLE } from '@/sdk/Participant.ts';
import { TranscriptWord } from '@/sdk/TranscriptWord.ts';
import { VoiceAssistantProvider, VoiceAssistantState } from '@/sdk/VoiceAssistant';
import { AuthService, AuthServiceImpl, getAuthService } from '@/service/AuthService.ts';
import { getDeviceService } from '@/service/DeviceService.ts';
import { useConferenceStore } from '@/store/ConferenceStore';
import { resetAllStores } from '@/store/create-disposable-store.ts';
import { useDeviceStore } from '@/store/DeviceStore';
import { useSettingsStore } from '@/store/SettingsStore';
import { useUIStore } from '@/store/UIStore.ts';
import { Session } from 'mind-sdk-web';
import { toast } from 'sonner';
import { create } from 'zustand';

export const getConferenceService = (): ConferenceService | undefined =>
  useConferenceServiceStore.getState().conferenceService;
export const useConferenceService = (): ConferenceService | undefined =>
  useConferenceServiceStore((state) => state.conferenceService);
export const initializeConferenceService = (sdk: IConferenceSDK): void => {
  if (!useConferenceServiceStore.getState().conferenceService) {
    useConferenceServiceStore.getState().setConferenceService(new ConferenceServiceImpl(sdk));
  }
};

export interface ConferenceService {
  createConferenceRoom(): Promise<string | null>;
  createParticipant(roomId: string): Promise<Participant>;
  deleteConference(conferenceId: string): Promise<void>;
  createAnonymousParticipant(roomId: string, name: string): Promise<Participant>;
  getUserByParticipantId(id: string): Promise<User | null>;
  getUsersByParticipantIds(ids: string[]): Promise<Record<string, User | null>>;
  updateParticipantRole(participantId: string, role: PARTICIPANT_ROLE): Promise<void>;
  expelParticipant(participantId: string): Promise<void>;
  muteParticipant(participantId: string): Promise<void>;
  setHandRaised(conferenceId: string, participantId: string, isRaised: boolean): Promise<void>;
  join(participant: Participant): Promise<void>;
  closeSession(): void;
  isParticipantMe(participantId: string): boolean;
  toggleParticipantPinning(participantId: string): void;
  toggleParticipantIgnoreVideoFlag(participantId: string): void;
  toggleMyVideoFloating(): void;
  toggleRecording(): Promise<void>;
  createRecordingToken(conferenceId: string): Promise<string>;
  togglePresenting(): Promise<void>;
  approveJoinRequest(joinRequestId: string): Promise<void>;
  rejectJoinRequest(joinRequestId: string): Promise<void>;
  getHistorySearchFields(): Promise<HistorySearchField[]>;
  getHistory(filters?: HistorySearchFilter[], options?: HistoryOptions): Promise<HistoryRecord[]>;
  shareConferenceHistoryRecord(conferenceId: string): Promise<string>;
  importConferenceHistoryRecord(token: string): Promise<HistoryRecord | null>;
  getChatMessages(roomId: string): Promise<ChatMessage[]>;
  sendAIMessage(roomId: string, aiModel: string, message: string): Promise<void>;
  getAIMessages(roomId: string): Promise<AIMessage[]>;
  getAIModels(): Promise<string[]>;
  getTranscript(conferenceId: string): Promise<TranscriptWord[]>;
  getVoiceAssistantState(conferenceId: string): Promise<VoiceAssistantState>;
  enableVoiceAssistant(conferenceId: string): Promise<void>;
  disableVoiceAssistant(conferenceId: string): Promise<void>;
  interruptVoiceAssistant(conferenceId: string): Promise<void>;
  changeVoiceAssistantProvider(conferenceId: string, provider: VoiceAssistantProvider): Promise<boolean>;
}

export class ConferenceServiceImpl implements ConferenceService {
  private _sdk: IConferenceSDK;
  private _authService: AuthService;
  private _deviceStoreUnsubscribe: (() => void) | null = null;

  constructor(sdk: IConferenceSDK) {
    this._sdk = sdk;
    this._authService = getAuthService() || new AuthServiceImpl(sdk);
  }

  async createConferenceRoom(): Promise<string | null> {
    return await this._sdk.createConferenceRoom();
  }

  async createParticipant(roomId: string): Promise<Participant> {
    const participant = await this._sdk.createParticipant(roomId);
    useConferenceStore.getState().setMindToken(participant.token);
    return participant;
  }

  async deleteConference(conferenceId: string): Promise<void> {
    await this._sdk.deleteConference(conferenceId);
  }

  async createAnonymousParticipant(roomId: string, name: string): Promise<Participant> {
    const anonymousUser = await this._authService.getAnonymousUser();
    const anonymousAuthToken = anonymousUser?.token;

    if (!anonymousAuthToken || anonymousUser?.roomId !== roomId || anonymousUser?.name !== name) {
      await this._authService.createAnonymousAuthToken(roomId, name);
    }

    const participant = await this._sdk.createAnonymousParticipant(roomId);
    useConferenceStore.getState().setMindToken(participant.token);

    return participant;
  }

  async getUserByParticipantId(userId: string): Promise<User | null> {
    const userDto = await this._sdk.getUserByParticipantId(userId);
    return userDto ? new User(userDto.id, userDto.email ?? '', userDto.firstName, userDto.lastName, '') : null;
  }

  async getUsersByParticipantIds(ids: string[]): Promise<Record<string, User | null>> {
    const usersDtoMap = await this._sdk.getUsersByParticipantIds(ids);

    const result: Record<string, User | null> = {};

    for (const [participantId, userDto] of Object.entries(usersDtoMap)) {
      result[participantId] = userDto
        ? new User(userDto.id, userDto.email ?? '', userDto.firstName, userDto.lastName, '')
        : null;
    }

    return result;
  }
  async updateParticipantRole(participantId: string, role: PARTICIPANT_ROLE): Promise<void> {
    try {
      await this._sdk.updateParticipantRole(participantId, role);
    } catch (e) {
      toast.error(`Can't update participant role: ${e.message}`);
    }
  }

  async expelParticipant(participantId: string): Promise<void> {
    try {
      await this._sdk.expelParticipant(participantId);
    } catch (e) {
      toast.error(`Can't expel participant: ${e.message}`);
    }
  }

  async muteParticipant(participantId: string): Promise<void> {
    try {
      await this._sdk.muteParticipant(participantId);
    } catch (e) {
      toast.error(`Can't mute participant: ${e.message}`);
    }
  }

  async setHandRaised(conferenceId: string, participantId: string, isRaised: boolean): Promise<void> {
    try {
      await this._sdk.setHandRaised(conferenceId, participantId, isRaised);
    } catch (e) {
      toast.error(`Can't ${isRaised ? 'raise' : 'lower'} hand: ${e.message}`);
    }
  }

  async join(participant: Participant): Promise<void> {
    const session = await this.initializeSession(participant);
    if (!session) {
      throw new ConferenceNotFoundError();
    }

    session.setListener(new SessionEventListener(this));

    const mindConference = session.getConference();

    const me = mindConference.getMe();

    const deviceService = getDeviceService();
    if (!deviceService) throw new Error('Device service is not initialized.');
    await deviceService.setupPrimaryMediaStream();
    await deviceService.setupSecondaryMediaStream();

    const participants = session.getConference()?.getParticipants() || [];
    participants?.forEach((participant) =>
      participant.getMediaStream().addAudioConsumer(new AudioStreamListener(participant.getId()))
    );

    // Recording
    useUIStore.getState().setRecordingEnabled(mindConference.isRecording());

    // Screen presenting
    const presentingParticipants = participants.filter((p) => p.isStreamingSecondaryVideo());

    // Prepare participants
    const conferenceStore = useConferenceStore.getState();
    conferenceStore.setConference({ ...participant.conference });
    conferenceStore.setSession(session);
    const participantWrappers = participants.map((p) => new MindParticipantWrapper(p));

    // Setup up my participant
    const meWrapper = new MindParticipantWrapper(me, true);
    meWrapper.setAnonymous(!(await this._authService.isAuthorized()));
    await this.setupMediaStreams(meWrapper);

    conferenceStore.addParticipants([
      meWrapper,
      ...participants.map((p) => new MindParticipantWrapper(p)),
      ...presentingParticipants.map((p) => new PresentingParticipant(p)),
    ]);

    // Asynchronously mark participants as anonymous
    this.getUsersByParticipantIds(participantWrappers.map((p) => p.getId()))
      .then((users) => {
        // set anonymous flag to participant if user is not found
        participantWrappers.forEach((p) => {
          const user = users[p.getId()];
          p.setAnonymous(!user);
          conferenceStore.updateParticipant(p);
        });
      })
      .catch(console.error);

    // Asynchronously mark participant's raised hands
    this._sdk
      .getRaisedHands(participant.conference.id)
      .then((raisedHands) => {
        raisedHands.forEach((rh) => {
          const p =
            meWrapper.getId() === rh.participantId
              ? meWrapper
              : participantWrappers.find((p) => p.getId() === rh.participantId);
          if (p) {
            p.setHandRaisedAt(rh.raisedAt);
            conferenceStore.updateParticipant(p);
          }
        });
      })
      .catch(console.error);

    const voiceAssistantState = await this.getVoiceAssistantState(participant.conference.id);
    conferenceStore.setVoiceAssistantState(voiceAssistantState);

    const isAuthorizedUser = await this._authService.isAuthorized();
    if (isAuthorizedUser) {
      const messages = await this._sdk.getChatMessages(participant.conference.id);
      useUIStore.getState().addMessages(messages);
    }
  }

  private async setupMediaStreams(meWrapper: MindParticipantWrapper): Promise<void> {
    if (!meWrapper) return;
    const primaryMediaStream = useDeviceStore.getState().primaryMediaStream;
    if (primaryMediaStream) {
      primaryMediaStream.addAudioConsumer(new AudioStreamListener(meWrapper.getId()));
      meWrapper.setMediaStream(primaryMediaStream);
    }

    const secondaryMediaStream = useDeviceStore.getState().secondaryMediaStream;
    if (secondaryMediaStream) {
      meWrapper.setSecondaryMediaStream(secondaryMediaStream);
    }

    // Subscribe to primary and secondary media streams changes
    this._deviceStoreUnsubscribe = useDeviceStore.subscribe((state, prevState) => {
      if (state.primaryMediaStream && prevState.primaryMediaStream !== state.primaryMediaStream && meWrapper) {
        state.primaryMediaStream.addAudioConsumer(new AudioStreamListener(meWrapper.getId()));
        meWrapper.setMediaStream(state.primaryMediaStream);
      }

      if (state.secondaryMediaStream && prevState.secondaryMediaStream !== state.secondaryMediaStream && meWrapper) {
        meWrapper.setSecondaryMediaStream(state.secondaryMediaStream);
      }
    });
  }

  private async initializeSession(participant: Participant): Promise<Session> {
    if (!participant || !participant.token) {
      throw new Error('Participant or token is not defined');
    }

    const baseUrl = import.meta.env.VITE_APP_BASE_URL;
    const mindAppId = participant.appId;
    const conferenceId = participant.conference.id;
    const conferenceUrl = `${baseUrl}/${mindAppId}/${conferenceId}`;
    return await this._sdk.joinToConference(conferenceUrl, participant.token);
  }

  closeSession(): void {
    const session = useConferenceStore.getState().session;
    if (session) {
      this._sdk.closeSession(session);
    }

    const deviceStore = useDeviceStore.getState();
    deviceStore.microphone?.destroy();
    deviceStore.camera?.destroy();
    deviceStore.screen?.release();

    if (this._deviceStoreUnsubscribe) {
      this._deviceStoreUnsubscribe();
      this._deviceStoreUnsubscribe = null;
    }

    resetAllStores();
  }

  isParticipantMe(participantId: string): boolean {
    const session = useConferenceStore.getState().session;
    const me = session?.getConference()?.getMe();
    return me?.getId() === participantId;
  }
  toggleParticipantPinning(participantId: string): void {
    const participants = useConferenceStore.getState().participants;
    const participant = participants.find((p) => p.getId() === participantId);
    if (participant) {
      participant.setPinned(!participant.isPinned());
      useConferenceStore.getState().updateParticipant(participant);
    }
  }

  toggleParticipantIgnoreVideoFlag(participantId: string): void {
    const participants = useConferenceStore.getState().participants;
    const participant = participants.find((p) => p.getId() === participantId);
    if (participant) {
      participant.setIgnoreVideo(!participant.shouldIgnoreVideo());
      useConferenceStore.getState().updateParticipant(participant);
    }
  }

  toggleMyVideoFloating(): void {
    const participants = useConferenceStore.getState().participants;
    const me = participants.find((p) => p.isMe());
    if (me) {
      me.setVideoFloating(!me.isVideoFloating());
      useConferenceStore.getState().updateParticipant(me);
    }
  }

  async toggleRecording(): Promise<void> {
    const conference = useConferenceStore.getState().conference;
    const session = useConferenceStore.getState().session;
    const recordingEnabled = useUIStore.getState().recordingEnabled;
    if (!conference || !session) {
      return;
    }

    try {
      if (recordingEnabled) {
        await this._sdk.stopRecording(conference.roomId);
        session?.fireOnConferenceRecordingStopped(session.getConference());
      } else {
        await this._sdk.startRecording(conference.roomId);
        session?.fireOnConferenceRecordingStarted(session.getConference());
      }
    } catch (e) {
      toast.error(`Can't ${recordingEnabled ? 'stop' : 'start'} recording: ${e.message}`);
    }
  }
  async createRecordingToken(conferenceId: string): Promise<string> {
    return await this._sdk.createRecordingToken(conferenceId);
  }

  async togglePresenting(): Promise<void> {
    const { screenPresentingEnabled, screen, setScreenPresentingEnabled } = useDeviceStore.getState();
    const conferenceStore = useConferenceStore.getState();
    const me = conferenceStore.participants.find((p) => p.isMe());
    const myPresentingParticipant = conferenceStore.participants.find(
      (p) => p.isPresentingParticipant() && (p as PresentingParticipant).getMindParticipant().getId() === me?.getId()
    );

    try {
      if (!screen) return;

      if (screenPresentingEnabled) {
        await screen.release();
        myPresentingParticipant && conferenceStore.removeParticipant(myPresentingParticipant?.getId());
      } else {
        await screen.acquire();
        me &&
          conferenceStore.addParticipant(
            new PresentingParticipant((me as MindParticipantWrapper).getMindParticipant())
          );
      }

      setScreenPresentingEnabled(!screenPresentingEnabled);
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        console.log('User denied screen presenting.');
      } else {
        toast.error(`Can't ${screenPresentingEnabled ? 'stop' : 'start'} screen presenting: ${error.message}`);
      }
    }
  }

  async approveJoinRequest(joinRequestId: string): Promise<void> {
    try {
      await this._sdk.approveJoinRequest(joinRequestId);
    } catch (e) {
      toast.error(`Can't approve join request: ${e.message}`);
    }
  }

  async rejectJoinRequest(joinRequestId: string): Promise<void> {
    try {
      await this._sdk.rejectJoinRequest(joinRequestId);
    } catch (e) {
      toast.error(`Can't reject join request: ${e.message}`);
    }
  }

  async getHistorySearchFields(): Promise<HistorySearchField[]> {
    try {
      return await this._sdk.getHistorySearchFields();
    } catch (e) {
      toast.error(`Can't get history search fields: ${e.message}`);
    }

    return [];
  }

  async getHistory(filters?: HistorySearchFilter[], options?: HistoryOptions): Promise<HistoryRecord[]> {
    try {
      return await this._sdk.getHistory(filters, options);
    } catch (e) {
      toast.error(`Can't get history: ${e.message}`);
    }

    return [];
  }

  async shareConferenceHistoryRecord(conferenceId: string): Promise<string> {
    try {
      return await this._sdk.shareConferenceHistoryRecord(conferenceId);
    } catch (e) {
      toast.error(`Can't share conference history record: ${e.message}`);
    }

    return '';
  }

  async importConferenceHistoryRecord(token: string): Promise<HistoryRecord | null> {
    try {
      return await this._sdk.importConferenceHistoryRecord(token);
    } catch (e) {
      toast.error(`Can't import conference history record: ${e.message}`);
    }

    return null;
  }

  async getChatMessages(conferenceId: string): Promise<ChatMessage[]> {
    try {
      return await this._sdk.getChatMessages(conferenceId);
    } catch (e) {
      toast.error(`Can't get chat messages: ${e.message}`);
    }

    return [];
  }

  async sendAIMessage(conferenceId: string, aiModel: string, message: string): Promise<void> {
    try {
      await this._sdk.sendAIMessage(conferenceId, aiModel, message);
    } catch (e) {
      toast.error(`Can't send AI message: ${e.message}`);
      throw e;
    }
  }

  async getAIMessages(roomId: string): Promise<AIMessage[]> {
    try {
      return await this._sdk.getAIMessages(roomId);
    } catch (e) {
      toast.error(`Can't get AI messages: ${e.message}`);
    }

    return [];
  }

  async getAIModels(): Promise<string[]> {
    try {
      return await this._sdk.getAIModels();
    } catch (e) {
      toast.error(`Can't get AI models: ${e.message}`);
    }

    return [];
  }

  async getTranscript(conferenceId: string): Promise<TranscriptWord[]> {
    try {
      return await this._sdk.getTranscript(conferenceId);
    } catch (e) {
      toast.error(`Can't get transcript: ${e.message}`);
    }

    return [];
  }

  async getVoiceAssistantState(conferenceId: string): Promise<VoiceAssistantState> {
    return await this._sdk.getVoiceAssistantState(conferenceId);
  }

  async enableVoiceAssistant(conferenceId: string): Promise<void> {
    try {
      const provider = useSettingsStore.getState().voiceAssistantProvider;
      await this._sdk.enableVoiceAssistant(conferenceId, provider);
    } catch (e) {
      toast.error(`Can't enable voice assistant: ${e.message}`);
    }
  }

  async disableVoiceAssistant(conferenceId: string): Promise<void> {
    try {
      await this._sdk.disableVoiceAssistant(conferenceId);
    } catch (e) {
      toast.error(`Can't disable voice assistant: ${e.message}`);
    }
  }

  async interruptVoiceAssistant(conferenceId: string): Promise<void> {
    try {
      await this._sdk.interruptVoiceAssistant(conferenceId);
    } catch (e) {
      toast.error(`Can't interrupt voice assistant: ${e.message}`);
    }
  }

  async changeVoiceAssistantProvider(conferenceId: string, provider: VoiceAssistantProvider): Promise<boolean> {
    try {
      await this._sdk.changeVoiceAssistantProvider(conferenceId, provider);
      return true;
    } catch (e) {
      toast.error(`Can't change voice assistant provider: ${e.message}`);
    }

    return false;
  }
}

interface ConferenceServiceStore {
  conferenceService: ConferenceService | undefined;
  setConferenceService: (service: ConferenceService) => void;
}

export const useConferenceServiceStore = create<ConferenceServiceStore>((set) => ({
  conferenceService: undefined,
  setConferenceService: (service: ConferenceService) => set({ conferenceService: service }),
}));
