import { useDeviceStore } from '@/store/DeviceStore';
import { MicrophoneListener } from 'mind-sdk-web';

export enum MicrophoneEvent {
  RELEASED = 'microphone:released',
}

export class MicrophoneEventListener extends EventTarget implements MicrophoneListener {
  onMicrophoneReleasedForcibly() {
    console.log('Microphone released forcibly');
    useDeviceStore.getState().setMicrophone(null);
    this.dispatchEvent(new Event(MicrophoneEvent.RELEASED));
  }
}
