import { ConferenceSDK, IConferenceSDK } from '@/sdk/ConferenceSDK.ts';
import { getAuthService } from '@/service/AuthService';
import { useAuthStore } from '@/store/AuthStore';
import { useConferenceStore } from '@/store/ConferenceStore';

const ROOM_ID_REGEX = /^[a-z]{3}-[a-z]{4}-[a-z]{3}$/;

export const createConferenceSDK = async (): Promise<IConferenceSDK> => {
  const sdk = new ConferenceSDK({
    apiPath: import.meta.env.VITE_APP_API_PATH,
    apiWsPath: import.meta.env.VITE_APP_API_WS_PATH,
    getApiToken: async () =>
      (await getAuthService()?.getUser())?.token || (await getAuthService()?.getAnonymousUser())?.token || '',
    onAuthError: () => getAuthService()?.login(),
    getCustomRequestHeaders: async () => {
      const user = useAuthStore.getState().user;
      const anonymousUser = useAuthStore.getState().anonymousUser;
      const roomIdStr = window.location.pathname.split('/')[1];
      const roomId = roomIdStr && ROOM_ID_REGEX.test(roomIdStr) ? roomIdStr : undefined;
      const conference = useConferenceStore.getState().conference;
      return {
        'X-Room-Id': roomId || conference?.roomId,
        'X-Conference-Id': conference?.id,
        'X-User-Id': user?.id || anonymousUser?.id,
      } as Record<string, string>;
    },
  });

  await sdk.initialize();
  return sdk;
};
