import { SpeakerRegistry } from '@/lib/speaker-registry.ts';
import { AIMessage } from '@/sdk/AIMessage.ts';
import { ChatMessage } from '@/sdk/ChatMessage.ts';
import { ConferenceSDKOptions } from '@/sdk/ConferenceSDKOptions.ts';
import { HandRaisedParticipant } from '@/sdk/HandRaisedParticipant.ts';
import { HistoryOptions, HistorySearchField, HistorySearchFilter } from '@/sdk/HistoryFilter';
import { HistoryRecord } from '@/sdk/HistoryRecord';
import { JoinRequestRejectedException } from '@/sdk/JoinRequestRejectedException.ts';
import { Participant, PARTICIPANT_ROLE, ParticipantRequestStatus } from '@/sdk/Participant.ts';
import { ParticipantJoinRequest } from '@/sdk/ParticipantJoinRequest.ts';
import { TranscriptWord } from '@/sdk/TranscriptWord.ts';
import { User } from '@/sdk/User.ts';
import { VoiceAssistantProvider, VoiceAssistantState } from '@/sdk/VoiceAssistant';
import axios, { AxiosInstance } from 'axios';
import {
  DeviceRegistry,
  MediaStream,
  MediaStreamAudioSupplier,
  MediaStreamVideoSupplier,
  MindSDK,
  MindSDKOptions,
  Session,
  SessionOptions,
} from 'mind-sdk-web';
import { io } from 'socket.io-client';

export interface IConferenceSDK {
  createConferenceRoom(): Promise<string>;
  createParticipant(roomId: string): Promise<Participant>;
  createAnonymousAuthToken(roomId: string, name: string): Promise<string>;
  refreshAnonymousAuthToken(anonymousAuthToken: string): Promise<string>;
  createAnonymousParticipant(roomId: string): Promise<Participant>;
  getUserByParticipantId(id: string): Promise<User | null>;
  getUsersByParticipantIds(ids: string[]): Promise<Record<string, User | null>>;
  updateParticipantRole(participantId: string, role: PARTICIPANT_ROLE): Promise<void>;
  expelParticipant(participantId: string): Promise<void>;
  muteParticipant(participantId: string): Promise<void>;
  getRaisedHands(conferenceId: string): Promise<HandRaisedParticipant[]>;
  setHandRaised(conferenceId: string, participantId: string, isRaised: boolean): Promise<void>;
  deleteConference(conferenceId: string): Promise<void>;
  joinToConference(conferenceUrl: string, participantToken: string): Promise<Session>;
  approveJoinRequest(joinRequestId: string): Promise<void>;
  rejectJoinRequest(joinRequestId: string): Promise<void>;
  getDeviceRegistry(): DeviceRegistry;
  getSpeakerRegistry(): SpeakerRegistry;
  createMediaStream(
    audioSupplier: MediaStreamAudioSupplier | null,
    videoSupplier: MediaStreamVideoSupplier | null
  ): MediaStream | null;
  closeSession(session: Session): void;
  startRecording(roomId: string): Promise<void>;
  stopRecording(roomId: string): Promise<void>;
  createRecordingToken(conferenceId: string): Promise<string>;
  sendMessageToAll(roomId: string, fromParticipantId: string, text: string): Promise<void>;
  getHistorySearchFields(): Promise<HistorySearchField[]>;
  getHistory(filters?: HistorySearchFilter[], options?: HistoryOptions): Promise<HistoryRecord[]>;
  shareConferenceHistoryRecord(conferenceId: string): Promise<string>;
  importConferenceHistoryRecord(token: string): Promise<HistoryRecord>;
  getChatMessages(roomId: string): Promise<ChatMessage[]>;
  sendAIMessage(roomId: string, aiModel: string, message: string): Promise<void>;
  getAIMessages(roomId: string): Promise<AIMessage[]>;
  getAIModels(): Promise<string[]>;
  getTranscript(conferenceId: string): Promise<TranscriptWord[]>;
  getVoiceAssistantState(conferenceId: string): Promise<VoiceAssistantState>;
  enableVoiceAssistant(conferenceId: string, provider: VoiceAssistantProvider): Promise<void>;
  disableVoiceAssistant(conferenceId: string): Promise<void>;
  interruptVoiceAssistant(conferenceId: string): Promise<void>;
  changeVoiceAssistantProvider(conferenceId: string, provider: VoiceAssistantProvider): Promise<void>;
}

export class ConferenceSDK implements IConferenceSDK {
  private httpClient: AxiosInstance;
  private options: ConferenceSDKOptions;
  private readonly speakerRegistry: SpeakerRegistry;

  constructor(options: ConferenceSDKOptions) {
    this.verifyOptions(options);
    this.options = options;
    this.httpClient = this.createHttpClient(options);
    this.speakerRegistry = new SpeakerRegistry();
  }

  async initialize(): Promise<void> {
    const mindSdkOptions = new MindSDKOptions();
    mindSdkOptions.setUseVp9ForSendingVideo(true);
    await MindSDK.initialize(mindSdkOptions);

    await this.speakerRegistry.update();
  }

  protected verifyOptions(options: ConferenceSDKOptions): void {
    if (!options.apiPath) {
      throw new Error('apiUrl is not defined');
    }

    if (!options.apiWsPath) {
      throw new Error('apiWsUrl is not defined');
    }

    if (!options.getApiToken) {
      throw new Error('apiTokenSource is not defined');
    }
  }

  protected createHttpClient(options: ConferenceSDKOptions): AxiosInstance {
    const instance = axios.create({
      baseURL: `${options.apiPath}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      timeout: 60000,
    });

    this.addAuthorizationInterceptor(instance);
    this.addAuthorizationErrorInterceptor(instance);
    this.addExtraHeadersRequestInterceptor(instance);

    return instance;
  }

  protected addAuthorizationInterceptor(httpClient: AxiosInstance) {
    httpClient.interceptors.request.use(async (config) => {
      const url = config.url ?? '';

      if (url.startsWith('/auth')) {
        return config;
      }

      const token = await this.options.getApiToken();
      if (token && !config.headers.Authorization) {
        config.headers.Authorization = 'Bearer ' + token;
      }

      return config;
    });
  }

  protected addAuthorizationErrorInterceptor(httpClient: AxiosInstance) {
    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response?.status;
        const url = error.config?.url || '';

        if (status === 401 && !url.startsWith('/auth')) {
          this.options.onAuthError && this.options.onAuthError();
        }

        return Promise.reject(error);
      }
    );
  }

  protected addExtraHeadersRequestInterceptor(httpClient: AxiosInstance) {
    httpClient.interceptors.request.use(async (config) => {
      if (this.options.getCustomRequestHeaders) {
        const headers = await this.options.getCustomRequestHeaders();
        Object.entries(headers).forEach(([key, value]) => {
          config.headers[key] = value;
        });
      }

      return config;
    });
  }

  async createConferenceRoom(): Promise<string> {
    const { data } = await this.httpClient.post<{ id: string }>('/rooms');
    return data?.id;
  }

  async createParticipant(roomId: string): Promise<Participant> {
    const { data } = await this.httpClient.post<Participant>(`/participants`, { roomId });
    return data;
  }

  async createAnonymousAuthToken(roomId: string, name: string): Promise<string> {
    const { data } = await this.httpClient.post<string>('/auth/anonymous', { roomId, name });
    return data;
  }

  async refreshAnonymousAuthToken(anonymousAuthToken: string): Promise<string> {
    const { data } = await this.httpClient.post<string>(
      '/auth/anonymous/refresh',
      {},
      {
        headers: { Authorization: `Bearer ${anonymousAuthToken}` }, // Need to manually specify token for routes starting from  /auth
      }
    );
    return data;
  }

  async createAnonymousParticipant(roomId: string): Promise<Participant> {
    const { data } = await this.httpClient.post<Participant | ParticipantJoinRequest>(`/participants/anonymous`, {});

    if (data._ === 'Participant') {
      return data as Participant;
    } else if (data._ === 'ParticipantJoinRequest') {
      return this.waitForJoinApproval(roomId, data.id);
    } else {
      throw new Error('Failed to create participant');
    }
  }

  async getUserByParticipantId(id: string): Promise<User | null> {
    try {
      const { data } = await this.httpClient.get<User>(`/participants/${id}/user`);
      return data;
    } catch (error) {
      if (error.response?.status === 404) return null;
      throw error;
    }
  }

  async getUsersByParticipantIds(ids: string[]): Promise<Record<string, User | null>> {
    if (!ids.length) return {};

    const params = new URLSearchParams();
    ids.forEach((id) => params.append('ids', id));

    try {
      const { data } = await this.httpClient.get<Record<string, User | null>>(
        `/participants/users?${params.toString()}`
      );
      return data;
    } catch (error) {
      if (error.response?.status === 404) return {};
      throw error;
    }
  }

  async updateParticipantRole(participantId: string, role: PARTICIPANT_ROLE): Promise<void> {
    await this.httpClient.patch(`/participants/${participantId}/role`, { role });
  }

  async expelParticipant(participantId: string): Promise<void> {
    await this.httpClient.post(`/participants/${participantId}/expel`);
  }

  async muteParticipant(participantId: string): Promise<void> {
    await this.httpClient.post(`/participants/${participantId}/mute`);
  }

  async getRaisedHands(conferenceId: string): Promise<HandRaisedParticipant[]> {
    const { data } = await this.httpClient.get<HandRaisedParticipant[]>(`/conferences/${conferenceId}/raised-hands`);
    return data;
  }

  async setHandRaised(conferenceId: string, participantId: string, isRaised: boolean): Promise<void> {
    if (isRaised) {
      await this.httpClient.post(`/conferences/${conferenceId}/raised-hands`, { participantId });
    } else {
      await this.httpClient.delete(`/conferences/${conferenceId}/raised-hands/${participantId}`);
    }
  }

  private async waitForJoinApproval(roomId: string, joinRequestId: string): Promise<Participant> {
    const token = await this.options.getApiToken();
    return new Promise((resolve, reject) => {
      const socket = io('/', {
        path: this.options.apiWsPath,
        transports: ['websocket'],
        upgrade: false,
        query: { roomId, joinRequestId },
        auth: { token: token },
      });

      socket.on('connect_error', (error) => reject(error));

      socket.on('join_request.approved', (participant: Participant) => {
        socket.disconnect();
        resolve(participant);
      });

      socket.on('join_request.rejected', () => {
        socket.disconnect();
        reject(new JoinRequestRejectedException());
      });
    });
  }
  async deleteConference(conferenceId: string): Promise<void> {
    await this.httpClient.delete(`/conferences/${conferenceId}`);
  }

  async joinToConference(conferenceUrl: string, participantToken: string): Promise<Session> {
    if (!conferenceUrl) {
      throw new Error('conferenceUrl is not defined');
    }

    if (!participantToken) {
      throw new Error('participantToken is not defined');
    }

    const options = new SessionOptions();
    options.setUseVp9ForSendingVideo(true);
    return MindSDK.join(conferenceUrl, participantToken, options);
  }

  async approveJoinRequest(joinRequestId: string): Promise<void> {
    await this.httpClient.patch(`/participants/join-requests/${joinRequestId}/status`, {
      status: ParticipantRequestStatus.APPROVED,
    });
  }

  async rejectJoinRequest(joinRequestId: string): Promise<void> {
    await this.httpClient.patch(`/participants/join-requests/${joinRequestId}/status`, {
      status: ParticipantRequestStatus.REJECTED,
    });
  }

  getDeviceRegistry(): DeviceRegistry {
    return MindSDK.getDeviceRegistry();
  }

  getSpeakerRegistry(): SpeakerRegistry {
    return this.speakerRegistry;
  }

  createMediaStream(
    audioSupplier: MediaStreamAudioSupplier | null,
    videoSupplier: MediaStreamVideoSupplier | null
  ): MediaStream | null {
    try {
      return MindSDK.createMediaStream(audioSupplier, videoSupplier);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  closeSession(session: Session): void {
    MindSDK.exit2(session);
  }

  async startRecording(roomId: string): Promise<void> {
    await this.httpClient.post(`/rooms/${roomId}/recordings/start`);
  }

  async stopRecording(roomId: string): Promise<void> {
    await this.httpClient.post(`/rooms/${roomId}/recordings/stop`);
  }

  async createRecordingToken(conferenceId: string): Promise<string> {
    const { data } = await this.httpClient.get(`/conferences/${conferenceId}/recordings/tokens`);
    return data.token;
  }

  async sendMessageToAll(conferenceId: string, fromParticipantId: string, text: string): Promise<void> {
    await this.httpClient.post(`/conferences/${conferenceId}/messages`, { participantId: fromParticipantId, text });
  }

  async getHistorySearchFields(): Promise<HistorySearchField[]> {
    const { data } = await this.httpClient.get<HistorySearchField[]>(`/conferences/history/search-fields`);
    return data;
  }

  async getHistory(filters?: HistorySearchFilter[], options?: HistoryOptions): Promise<HistoryRecord[]> {
    const { data } = await this.httpClient.post<HistoryRecord[]>(`/conferences/history`, { filters, options });
    return data;
  }

  async shareConferenceHistoryRecord(conferenceId: string): Promise<string> {
    const { data } = await this.httpClient.post(
      `/conferences/${conferenceId}/history/share`,
      {},
      {
        responseType: 'text',
      }
    );
    return data;
  }

  async importConferenceHistoryRecord(token: string): Promise<HistoryRecord> {
    const { data } = await this.httpClient.post(`/conferences/history/import`, { token });
    return data;
  }

  async getChatMessages(conferenceId: string): Promise<ChatMessage[]> {
    const { data } = await this.httpClient.get<ChatMessage[]>(`/conferences/${conferenceId}/messages`);
    return data;
  }

  async sendAIMessage(conferenceId: string, aiModel: string, message: string): Promise<void> {
    await this.httpClient.post(`/ai/messages`, { conferenceId, aiModel, text: message });
  }

  async getAIMessages(roomId: string): Promise<AIMessage[]> {
    const { data } = await this.httpClient.get<AIMessage[]>(`/ai/messages?roomId=${roomId}`);
    return data;
  }

  async getAIModels(): Promise<string[]> {
    const { data } = await this.httpClient.get<string[]>(`/ai/models`);
    return data;
  }

  async getTranscript(conferenceId: string): Promise<TranscriptWord[]> {
    const { data } = await this.httpClient.get<TranscriptWord[]>(`/conferences/${conferenceId}/transcript`);
    return data;
  }

  async getVoiceAssistantState(conferenceId: string): Promise<VoiceAssistantState> {
    const { data } = await this.httpClient.get<VoiceAssistantState>(
      `/voice-assistant/state?conferenceId=${conferenceId}`
    );
    return data;
  }

  async enableVoiceAssistant(conferenceId: string, provider: VoiceAssistantProvider): Promise<void> {
    await this.httpClient.post('/voice-assistant/enable', { conferenceId, provider });
  }

  async disableVoiceAssistant(conferenceId: string): Promise<void> {
    await this.httpClient.post('/voice-assistant/disable', { conferenceId });
  }

  async interruptVoiceAssistant(conferenceId: string): Promise<void> {
    await this.httpClient.post('/voice-assistant/interrupt', { conferenceId });
  }

  async changeVoiceAssistantProvider(conferenceId: string, provider: VoiceAssistantProvider): Promise<void> {
    await this.httpClient.post('/voice-assistant/provider/change', { conferenceId, provider });
  }
}
