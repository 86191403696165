import { CameraEvent, CameraEventListener } from '@/event/CameraEventListener.ts';
import { MicrophoneEvent, MicrophoneEventListener } from '@/event/MicrophoneEventListener.ts';
import { isMarkedAsSystemDefault } from '@/lib/default-device-recogniser.ts';
import { Speaker } from '@/lib/speaker-registry.ts';
import { IConferenceSDK } from '@/sdk/ConferenceSDK.ts';
import { DeviceService } from '@/service/DeviceService';
import { useDeviceStore } from '@/store/DeviceStore.ts';
import { useSettingsStore } from '@/store/SettingsStore';
import { Camera, Microphone } from 'mind-sdk-web';
import { toast } from 'sonner';

function showMicrophoneInstructions() {
  toast.error('Microphone access is blocked. Please enable it in your browser settings to use it.', {
    duration: 5000,
  });
}

function showCameraInstructions() {
  toast.error('Camera access is blocked. Please enable it in your browser settings to use it.', {
    duration: 5000,
  });
}

/**
 * Base interface for devices that can be acquired and released
 */
interface AcquirableDevice {
  getId(): string;
  acquire(): Promise<void>;
  release(): Promise<void>;
}

/**
 * Abstract base class for managing device lifecycle and state
 * @template T - Type of device being managed (must be both AcquirableDevice and either Microphone or Camera)
 */
abstract class DeviceManager<T extends AcquirableDevice & (Microphone | Camera)> {
  abstract getAvailableDevices(): T[];
  abstract getCurrentDevice(): T | null;
  abstract setCurrentDevice(device: T | null): void;
  abstract getPreviousDeviceId(): string | null;
  abstract setPreviousDeviceId(id: string): void;
  abstract isEnabled(): boolean;
  abstract setEnabled(enabled: boolean): void;

  async toggleDevice(): Promise<void> {
    const currentDevice = this.getCurrentDevice();
    if (!currentDevice) {
      await this.useDefaultDevice();
      return;
    }

    try {
      if (this.isEnabled()) {
        await this.releaseDevice(currentDevice);
      } else {
        await this.acquireDevice(currentDevice);
      }
      this.setEnabled(!this.isEnabled());
    } catch (error) {
      toast.error(`Failed to toggle device: ${error.message}`);
      throw error;
    }
  }

  /**
   * Acquires and initializes a device
   * @param device - The device to acquire
   */
  async acquireDevice(device: T): Promise<void> {
    if (!device) return;

    try {
      await device.acquire();
      this.setCurrentDevice(device);
      this.setPreviousDeviceId(device.getId());
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        console.log('Permission denied for device');
        this.setCurrentDevice(null);
      } else {
        toast.error(`Failed to acquire device: ${error.message}`);
        throw error;
      }
    }
  }

  /**
   * Releases a device and cleans up resources
   * @param device - The device to release
   */
  async releaseDevice(device: T): Promise<void> {
    if (!device) return;

    try {
      await device.release();
    } catch (error) {
      toast.error(`Failed to release device: ${error.message}`);
      throw error;
    }
  }

  /**
   * Selects and initializes the default device based on previous selection or system defaults
   */
  async useDefaultDevice(): Promise<T | null> {
    const devices = this.getAvailableDevices();
    const prevSelectedId = this.getPreviousDeviceId();

    const device =
      devices.find((d) => d.getId() === prevSelectedId) ||
      devices.find((d) => isMarkedAsSystemDefault(d)) ||
      devices[0];

    if (this.getCurrentDevice()?.getId() !== device?.getId()) {
      await this.useDevice(device);
    }

    return device;
  }

  /**
   * Switches to a new device, releasing the current one if necessary
   * @param device - The new device to use
   */
  async useDevice(device: T | null): Promise<void> {
    const currentDevice = this.getCurrentDevice();
    if (currentDevice) {
      await this.releaseDevice(currentDevice);
    }
    if (device) {
      await this.acquireDevice(device);
    }
  }
}

/**
 * Manages microphone device operations including acquisition, release, and state management
 */
export class MicrophoneManager extends DeviceManager<Microphone> {
  constructor(
    private readonly deviceService: DeviceService,
    private readonly sdk: IConferenceSDK
  ) {
    super();
  }

  getAvailableDevices(): Microphone[] {
    return useDeviceStore.getState().availableMicrophones;
  }

  getCurrentDevice(): Microphone | null {
    return useDeviceStore.getState().microphone;
  }

  setCurrentDevice(device: Microphone | null): void {
    useDeviceStore.getState().setMicrophone(device);
  }

  getPreviousDeviceId(): string | null {
    return useSettingsStore.getState().selectedMicrophoneId;
  }

  setPreviousDeviceId(id: string): void {
    useSettingsStore.getState().setMicrophoneId(id);
  }

  isEnabled(): boolean {
    return useDeviceStore.getState().microphoneEnabled;
  }

  setEnabled(enabled: boolean): void {
    useDeviceStore.getState().setMicrophoneEnabled(enabled);
  }

  /**
   * Acquires and initializes a microphone with noise suppression and event listener
   */
  async acquireDevice(device: Microphone): Promise<void> {
    if (!device) return;

    try {
      const eventListener = new MicrophoneEventListener();
      device.setListener(eventListener);
      await device.acquire();

      // During first acquisition, device is forcibly released, so try to acquire it again
      const microphoneReleaseHandler = async () => await this.acquireDevice(device);
      eventListener.addEventListener(MicrophoneEvent.RELEASED, microphoneReleaseHandler);
      setTimeout(() => eventListener.removeEventListener(MicrophoneEvent.RELEASED, microphoneReleaseHandler), 3000);

      device.setNoiseSuppression(true);
      this.setCurrentDevice(device);
      this.setPreviousDeviceId(device.getId());

      // We need to port this kludge from MindSDK to retrieve actual speaker list after getting
      // permissions to use the microphone. Reproducible with Chrome+Win+AnonymousTab
      // See: https://gitlab.com/mindlabs/api/sdk/web/-/blob/master/lib/Microphone.js?ref_type=heads#L140
      if (useDeviceStore.getState().availableMicrophones.length === 1) {
        this.sdk
          .getSpeakerRegistry()
          .update()
          .catch((error: Error) => {
            toast.error(`Can't update speakers: ${error.message}`);
          });
      }

      this.deviceService.setupPrimaryMediaStream();
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        console.log('Microphone permission denied');
        this.setCurrentDevice(null);
        navigator.permissions
          .query({ name: 'microphone' as PermissionName })
          .then((permissionStatus) => {
            if (permissionStatus.state === 'denied') {
              showMicrophoneInstructions();
            }
          })
          .catch(() => {
            // If permissions API is not supported, show generic message
            showMicrophoneInstructions();
          });
      } else if (error.message?.includes('AudioWorkletNode')) {
        // If noise suppression fails, try to acquire the device without it
        console.warn('Noise suppression failed, falling back to basic microphone mode');
        device.setNoiseSuppression(false);
        await device.acquire();
        this.setCurrentDevice(device);
        this.setPreviousDeviceId(device.getId());
        this.deviceService.setupPrimaryMediaStream();
      } else {
        toast.error(`Failed to acquire microphone: ${error.message}`);
        throw error;
      }
    }
  }
}

/**
 * Manages camera device operations including acquisition, release, and state management
 */
export class CameraManager extends DeviceManager<Camera> {
  constructor(private readonly deviceService: DeviceService) {
    super();
  }

  getAvailableDevices(): Camera[] {
    return useDeviceStore.getState().availableCameras;
  }

  getCurrentDevice(): Camera | null {
    return useDeviceStore.getState().camera;
  }

  setCurrentDevice(device: Camera | null): void {
    useDeviceStore.getState().setCamera(device);
  }

  getPreviousDeviceId(): string | null {
    return useSettingsStore.getState().selectedCameraId;
  }

  setPreviousDeviceId(id: string): void {
    useSettingsStore.getState().setCameraId(id);
  }

  isEnabled(): boolean {
    return useDeviceStore.getState().cameraEnabled;
  }

  setEnabled(enabled: boolean): void {
    useDeviceStore.getState().setCameraEnabled(enabled);
  }

  /**
   * Acquires and initializes a camera with event listener
   */
  async acquireDevice(device: Camera): Promise<void> {
    if (!device) return;

    try {
      const eventListener = new CameraEventListener();
      device.setListener(eventListener);
      await device.acquire();

      // During first acquisition, device is forcibly released, so try to acquire it again
      const cameraReleaseHandler = async () => await this.acquireDevice(device);
      eventListener.addEventListener(CameraEvent.RELEASED, cameraReleaseHandler);
      setTimeout(() => eventListener.removeEventListener(CameraEvent.RELEASED, cameraReleaseHandler), 3000);

      this.setCurrentDevice(device);
      this.setPreviousDeviceId(device.getId());

      this.deviceService.setupPrimaryMediaStream();
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        console.log('Camera permission denied');
        this.setCurrentDevice(null);
        navigator.permissions
          .query({ name: 'camera' as PermissionName })
          .then((permissionStatus) => {
            if (permissionStatus.state === 'denied') {
              showCameraInstructions();
            }
          })
          .catch(() => {
            // If permissions API is not supported, show generic message
            showCameraInstructions();
          });
      } else {
        toast.error(`Failed to acquire camera: ${error.message}`);
        throw error;
      }
    }
  }
}

/**
 * Manages speaker device operations
 * Note: Speakers don't need to be acquired/released, they're always enabled
 */
export class SpeakerManager {
  getAvailableDevices(): Speaker[] {
    return useDeviceStore.getState().availableSpeakers;
  }

  getCurrentDevice(): Speaker | null {
    return useDeviceStore.getState().speaker;
  }

  setCurrentDevice(device: Speaker | null): void {
    useDeviceStore.getState().setSpeaker(device);
  }

  getPreviousDeviceId(): string | null {
    return useSettingsStore.getState().selectedSpeakerId;
  }

  setPreviousDeviceId(id: string): void {
    useSettingsStore.getState().setSpeakerId(id);
  }

  isEnabled(): boolean {
    return true; // Speakers are always enabled
  }

  setEnabled(): void {
    // No-op as speakers are always enabled
  }

  /**
   * Switches to a new speaker device
   */
  async useDevice(device: Speaker | null): Promise<void> {
    if (!device) return;
    this.setCurrentDevice(device);
    this.setPreviousDeviceId(device.getId());
  }

  /**
   * Selects and initializes the default speaker based on previous selection or system defaults
   */
  async useDefaultDevice(): Promise<Speaker | null> {
    const devices = this.getAvailableDevices();
    const prevSelectedId = this.getPreviousDeviceId();

    const device =
      devices.find((d) => d.getId() === prevSelectedId) ||
      devices.find((d) => isMarkedAsSystemDefault(d)) ||
      devices[0];

    if (this.getCurrentDevice()?.getId() !== device?.getId()) {
      await this.useDevice(device);
    }

    return device;
  }
}
