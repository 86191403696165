export enum VoiceAssistantStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  PENDING = 'pending',
}

export type VoiceAssistantState = {
  status: VoiceAssistantStatus;
  prevStatus: VoiceAssistantStatus;
  provider: VoiceAssistantProvider;
};

export enum VoiceAssistantProvider {
  MIND = 'mind',
  OPENAI = 'openai',
  HEYGEN = 'heygen',
}
