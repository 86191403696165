import { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogPortal,
  DialogTitle,
} from '@/components/ui/dialog';
import { FaCopy } from 'react-icons/fa';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { LoadingSpinner } from '@/components/ui/spinner.tsx';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  description: string;
  value: string | null;
};

export const CopyTextDialog: FC<Props> = ({ open, onOpenChange, title, description, value }) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogContent className="max-w-[90%] sm:max-w-md mx-auto rounded-lg">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>

          {!value ? (
            <div className="flex justify-center items-center h-32">
              <LoadingSpinner size={42} />
            </div>
          ) : (
            <>
              <DialogDescription>{description}</DialogDescription>
              <div className="flex items-center space-x-2">
                <div className="grid flex-1 gap-2">
                  <Label htmlFor="copy-input" className="sr-only">
                    Value
                  </Label>
                  {/*kludge: Set 'tabIndex' to avoid receiving focus after dialog creation */}
                  <Input id="copy-input" value={value} readOnly tabIndex={-1} className="w-full" />
                </div>
                <Button
                  type="submit"
                  size="sm"
                  className="px-3 bg-white text-black hover:bg-gray-200 hover:text-gray-500"
                  onClick={async () => await navigator.clipboard.writeText(value)}
                >
                  <span className="sr-only">Copy</span>
                  <FaCopy className="h-4 w-4 text-black" />
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default CopyTextDialog;
