import { generateName } from '@/lib/name-generator.ts';
import { MockParticipant, Participant } from '@/model/Participant.ts';
import { Conference } from '@/sdk/Conference.ts';
import { VoiceAssistantState } from '@/sdk/VoiceAssistant';
import { create } from '@/store/create-disposable-store.ts';
import { Session } from 'mind-sdk-web';
import { subscribeWithSelector } from 'zustand/middleware';
import { ParticipantJoinRequest } from '@/sdk/ParticipantJoinRequest.ts';

export interface ConferenceStore {
  conference: Conference | null;
  setConference: (conference: Conference | null) => void;

  mindToken: string | null;
  setMindToken: (token: string | null) => void;

  session: Session | null;
  setSession: (session: Session | null) => void;

  participants: Participant[];
  addParticipant: (participant: Participant) => void;
  addParticipants: (participants: Participant[]) => void;
  updateParticipant: (participant: Participant) => void;
  removeParticipant: (participantId: string) => void;
  subscribeToParticipants: (
    callback: (oldParticipants: Participant[], newParticipants: Participant[]) => void
  ) => () => void;

  addMockParticipant: () => void;
  removeMockParticipant: () => void;

  joinRequests: ParticipantJoinRequest[];
  addJoinRequest: (request: ParticipantJoinRequest) => void;
  removeJoinRequest: (requestId: string) => void;

  voiceAssistantState: VoiceAssistantState | null;
  setVoiceAssistantState: (state: VoiceAssistantState | null) => void;

  voiceAssistantSpeaking: boolean;
  setVoiceAssistantSpeaking: (speaking: boolean) => void;
}

const mergeParticipants = (participants: Participant[]) => [
  ...new Map(participants.map((p) => [p.getId(), p])).values(),
];

const filterParticipant = (participants: Participant[], participant: Participant) =>
  participants.filter((p) => p.getId() !== participant.getId());

export const useConferenceStore = create(
  subscribeWithSelector<ConferenceStore>((set) => ({
    conference: null,
    setConference: (conference: Conference | null) => set({ conference }),

    mindToken: null,
    setMindToken: (token: string | null) => set({ mindToken: token }),

    session: null,
    setSession: (session: Session | null) => set({ session }),

    participants: [],

    addParticipants: (participants: Participant[]) =>
      set((state) => ({ participants: mergeParticipants([...state.participants, ...participants]) })),

    addParticipant: (participant: Participant) =>
      set((state) => ({ participants: mergeParticipants([...state.participants, participant]) })),

    updateParticipant: (participant: Participant) =>
      set((state) => ({
        participants: mergeParticipants([...filterParticipant(state.participants, participant), participant]),
      })),

    removeParticipant: (participantId) =>
      set((state) => ({
        participants: state.participants.filter((p) => p.getId() !== participantId),
      })),

    subscribeToParticipants: (
      callback: (oldParticipants: Participant[], newParticipants: Participant[]) => void
    ): (() => void) => {
      return useConferenceStore.subscribe(
        (state) => state.participants,
        (newParticipants: Participant[], oldParticipants: Participant[] = []) => {
          callback(oldParticipants, newParticipants);
        }
      );
    },

    addMockParticipant: () =>
      set((state) => ({
        participants: [...state.participants, new MockParticipant(Math.random().toString(16).slice(2), generateName())],
      })),
    removeMockParticipant: () =>
      set((state) => {
        const index = state.participants.findIndex((p) => !p.isReal());
        if (index === -1) return state;

        return {
          participants: [...state.participants.slice(0, index), ...state.participants.slice(index + 1)],
        };
      }),

    joinRequests: [],
    addJoinRequest: (request: ParticipantJoinRequest) =>
      set((state) => ({ joinRequests: [...state.joinRequests, request] })),
    removeJoinRequest: (requestId: string) =>
      set((state) => ({ joinRequests: state.joinRequests.filter((r) => r.id !== requestId) })),

    voiceAssistantState: null,
    setVoiceAssistantState: (state: VoiceAssistantState | null) => set({ voiceAssistantState: state }),

    voiceAssistantSpeaking: false,
    setVoiceAssistantSpeaking: (speaking: boolean) => set({ voiceAssistantSpeaking: speaking }),
  }))
);
