import { ChatMessage } from '@/sdk/ChatMessage.ts';
import { create } from '@/store/create-disposable-store.ts';
import { subscribeWithSelector } from 'zustand/middleware';

export enum ConferencePageState {
  JOINING,
  LOBBY,
  READY,
  FINISHED,
}

export enum SidePanelType {
  PARTICIPANTS,
  CHAT,
  SETTINGS,
  TRANSCRIPT,
  AI_ASSISTANT,
}

interface UIStore {
  conferencePageState: ConferencePageState;
  setConferencePageState: (state: ConferencePageState) => void;

  activeSidePanel: SidePanelType | null;
  setActiveSidePanel: (panel: SidePanelType | null) => void;

  hasUnreadMessage: boolean;
  setHasUnreadMessage: (unread: boolean) => void;

  messages: ChatMessage[];
  addMessage: (message: ChatMessage) => void;
  addMessages: (messages: ChatMessage[]) => void;

  recordingEnabled: boolean;
  setRecordingEnabled: (recordingEnabled: boolean) => void;
  subscribeToRecordingEnabled: (callback: (isRecording: boolean) => void) => () => void;

  showChangeLayoutDialog: boolean;
  setShowChangeLayoutDialog: (showChangeLayoutDialog: boolean) => void;

  conferenceEndingAt: number | null;
  setConferenceEndingAt: (endingAt: number) => void;
}

export const useUIStore = create(
  subscribeWithSelector<UIStore>((set, get) => {
    return {
      conferencePageState: ConferencePageState.LOBBY,
      setConferencePageState: (state: ConferencePageState) => set({ conferencePageState: state }),

      activeSidePanel: null,
      setActiveSidePanel: (panel: SidePanelType | null) => {
        if (panel === SidePanelType.CHAT) {
          set({ activeSidePanel: panel, hasUnreadMessage: false });
        } else {
          set({ activeSidePanel: panel });
        }
      },

      hasUnreadMessage: false,
      setHasUnreadMessage: (unread: boolean) => {
        const isChatPanelActive = get().activeSidePanel === SidePanelType.CHAT;
        set({ hasUnreadMessage: unread && !isChatPanelActive });
      },

      messages: [],
      addMessage: (message: ChatMessage) => set((state) => ({ messages: [...state.messages, message] })),
      addMessages: (messages: ChatMessage[]) => set((state) => ({ messages: [...state.messages, ...messages] })),

      recordingEnabled: false,
      setRecordingEnabled: (recordingEnabled: boolean) => set({ recordingEnabled }),
      subscribeToRecordingEnabled: (callback: (isRecording: boolean) => void): (() => void) => {
        return useUIStore.subscribe(
          (state) => state.recordingEnabled,
          (isRecording) => {
            callback(isRecording);
          }
        );
      },

      showChangeLayoutDialog: false,
      setShowChangeLayoutDialog: (showChangeLayoutDialog: boolean) => set({ showChangeLayoutDialog }),

      conferenceEndingAt: null,
      setConferenceEndingAt: (endingAt: number) => set({ conferenceEndingAt: endingAt }),
    };
  })
);
